<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>
      </md-card-header>

      <md-card-content>
        <div class="md-layout mb-5">
          <div class="md-layout-item md-size-100">
            <ProgrammeStatusesSelect
              v-model="filters.status"
            />
          </div>

          <div class="md-layout-item md-size-100">
            <md-button
              class="md-success md-sm w-100"
              @click="onFilter"
            >
              Filter
            </md-button>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @changed="getData"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { ProgrammeStatusesSelect } from '@/components/Inputs/selects';

export default {
  components: {
    ProgrammeStatusesSelect,
    VueTable,
  },
  data: () => ({
    vTable: {
      headers: [
        {
          title: 'student.student_number',
          mask: 'student no.',
          sortable: true,
        },
        {
          title: 'student.full_name',
          mask: 'name',
          sort_value: 'student.first_name',
          sortable: true,
        },
        {
          title: 'student.programme.status',
          mask: 'programme status',
          sortable: true,
        },
        {
          title: 'reg_number',
          mask: 'IRP Number',
          no_format: true,
          sortable: true,
        },
        {
          title: 'expiration_date',
          mask: 'expiration date',
          sortable: true,
          dateFormat: true,
        },
        {
          title: 'stamp',
          sortable: true,
        },
      ],
      values: {},
      actions: [],
    },
    filters: {
      status: null,
    },
    loading: false,
  }),
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    onFilter() {
      this.$refs.vtable.init();
    },
    getData(params) {
      this.loading = true;

      this.request('students/gnib/report', 'get', { ...this.filters, ...params }, ({ data }) => {
        this.vTable.values = data;
      }, () => {
        this.loading = false;
      });
    },
  },
};
</script>

<style>

</style>
